import React from 'react';
import { Stack, Typography, CircularProgress } from '@mui/material';

const LoadingSpinner = ({ text }) => {
  return (
    <Stack sx={{display:"flex", alignItems:"center", mt:"5vh", mb:"90vh"}}>
      <CircularProgress size="8vw" disableShrink/>
      <Typography p={2} color="primary" variant="body1">
        {text}
      </Typography>
    </Stack>
  );
};

export default LoadingSpinner;
