import * as React from 'react';
import { Box, Stack, useMediaQuery, Typography } from '@mui/material';
import Faq from "react-faq-component";

const FaqScreen = () => {
    const mobile = useMediaQuery("(max-width: 800px)");
    const textStyle={fontWeight: 900, fontSize: mobile ? 18 : 20,fontFamily: "'Work Sans', sans-serif", color: "white"}
    
    const data = {
        rows: [
            {
                title: <span style={textStyle}><b>What is EZread?</b></span>,
                content: `EZread is a hub for easy-to-read textbook summaries written by vetted scholars to help you study for your classes with high quality notes.`,
            },
            {
                title: <span style={textStyle}><b>Who writes EZread summaries?</b></span>,
                content:
                    "We gather our textbook summaries through vetted writers–like graduate students, PhD candidates, and scholars–who have already mastered the material that they summarize and know what students need to know to learn the content.",
            },
            {
              title: <span style={textStyle}><b>What should I do if EZread does not have my textbook?</b></span>,
              content: `Our team is continually working to add more textbooks to our collection, so feel free to fill out this <a style={{color: "#97dceb"}} href="https://forms.gle/ovf8HguL1knEXVfk8" target="_blank">form</a> with information regarding your desired textbook. We make sure to pay due diligence to contain correct and essential information in our summaries, so it may take us a few months.`,
            },
            {
              title: <span style={textStyle}><b>How can I cite EZread?</b></span>,
              content: <><span>To cite EZread, write “EZread Writers” in place of authors and use “2022” as the publication date. Below are some examples:</span><br/><br/>
              <span>&emsp;MLA:</span><br/>
              <span style={{color: "#97dceb"}}>&emsp;&emsp;EZread Writers. “EZread Summary Title.” EZread.io, EZread LLC, 2024, URL.</span><br/><br/>
              <span>&emsp;APA:</span><br/>
              <span style={{color: "#97dceb"}}>&emsp;&emsp;EZread Writers. (2024). EZread Summary Title. Retrieved from URL.</span><br/><br/>
              <span>&emsp;Chicago:</span><br/>
              <span style={{color: "#97dceb"}}>&emsp;&emsp;EZread Writers. “EZread Summary Title.” EZread.io. 2024. Accessed August 04, 2024. URL.</span></>,
            },
            {
              title: <span style={textStyle}><b>How can I suggest changes for a summary?</b></span>,
              content: `We try our best to create the highest quality summaries that we can, but in the world of academia, it’s impossible to be perfect. If you notice any incorrect or missing information, please leave a review on the chapter summary with a description of the error you noticed.`,
            },
            {
              title: <span style={textStyle}><b>Any questions we didn't answer?</b></span>,
              content: `Feel free to <a href="https://forms.gle/b5Zj51kKFeQcLbHq7" target="_blank">contact</a> us.`,
            },
        ],
      };
    
      const styles = {
          // bgColor: 'white',
            bgColor: `transparent`,
            titleTextColor: "white",
            rowTitleColor: "white",
            rowContentColor: 'white',
            arrowColor: "white",

      };
    
      const config = {
          // animate: true,
          // arrowIcon: "V",
          // tabFocus: true
      };
    return (
        <Stack alignItems="center" spacing={2} p={6}>
        {mobile ? <Typography variant="h4" align="center" gutterBottom> Frequently Asked Questions </Typography> :
        <Typography variant="h2" align="center" gutterBottom> Frequently Asked Questions </Typography> }
        <Box width="80%" pb="10vh" pt={2}>
            <Faq
            data={data}
            styles={styles}
            config={config}
            />
        </Box>
    </Stack>
    );
  }
  
  export default FaqScreen;
