import React from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';

const SearchBar = ({ searchText, setSearchText, handleClear }) => {
  const navigate = useNavigate();

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      navigate(`/search/query=${searchText}&subject=&publisher=&edition=&year=&page=1&sortBy=Default`);
    }
  };

  return (
    <TextField
      variant="outlined"
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
      onKeyPress={handleKeyPress}
      placeholder="Search..."
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: searchText && (
          <InputAdornment position="end">
            <IconButton onClick={handleClear}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      fullWidth
      sx={{
        '& .MuiOutlinedInput-root': {
        borderRadius: '50px',
        padding: '0 12px',
      },
      '& .MuiInputBase-input': {
        padding: '10px 0',
      },
    }}
    />
  );
};

export default SearchBar;