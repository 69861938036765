import * as React from 'react';
import { useNavigate} from 'react-router-dom';
import { Box, Stack, useMediaQuery, Typography, Link } from '@mui/material';
//import { Link } from "@remix-run/react";

const AboutScreen = () => {
    const navigate = useNavigate();

    const contactHandler = () => {
        window.open("https://forms.gle/b5Zj51kKFeQcLbHq7");
    }


    const summaryHandler = () => {
        navigate("/search/query=2000,2011,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022&subject=&publisher=&edition=&year=&page=1&sortBy=Default");
        window.location.reload();
    }

    const applyHandler = () => {
        window.open("https://bit.ly/EZread");
    }

    const minWidth850 = useMediaQuery('(min-width:850px)');
    
    return (
        <>
      { minWidth850 ? 
      <>
      <Stack display="flex" 
            alignItems="center"
            justifyContent="center"
            sx={{mt: 4}}>
                
            <Typography className="desc" sx={{fontSize:60, fontWeight: "700", color:"#fff"}}>
            EZread:
            </Typography>
            <Typography className="desc" sx={{fontSize:50, fontWeight: "500", color:"#fff"}}>
            For Students, By Students
            </Typography>
        </Stack>
        <Box display="flex" 
            alignItems="center"
            justifyContent="center"
            mx="12vw"
            mt="40px"
            mb="20px"
            sx={{ color: 'common.white', fontSize: '15px'}}>
              <Typography className="desc" sx={{fontSize:20, fontWeight: 500}}>Need assistance with learning from textbooks?
                <br /><br />
                EZread has compiled textbook summaries written by vetted scholars
                 to help you study for your classes with high quality notes
                <br /><br />
                Textbooks are huge, intimidating, and difficult to study with. They
                have their place in education, but we wanted a more efficient
                way to cram in late night study sessions.
                <br /><br />
                Rishi, Alvin, and Aidan, our 3 founders, have worked to launch this
                service to help college students around the globe study for exams and assignments.
                <br /><br />
                Simply click on the <Link underline="hover" onClick={summaryHandler} sx= {{'&:hover': {cursor:"pointer"}}}>summaries</Link> tab, and browse through our collection
                to find a textbook that you need help with.
                <br /><br />
                If we are missing a textbook that you would like added, 
                    please fill out our feedback form by clicking on the <Link underline="hover" onClick={contactHandler} sx= {{'&:hover': {cursor:"pointer"}}}>contact</Link> tab!
                    Feel free to use that form to submit any other questions or concerns you have
                <br /><br />
                If you'd like to join our team of qualified writers, click here to <Link underline="hover" onClick={applyHandler} sx= {{'&:hover': {cursor:"pointer"}}}>apply</Link>.
                <br /><br />
                </Typography>
        </Box>
      </> : <>
      <Stack display="flex" 
            alignItems="center"
            justifyContent="center"
            sx={{mt: 2}}>
                
            <Typography className="desc" sx={{fontSize:34, fontWeight: "900"}}>
            EZread:
            </Typography>
            <Typography className="desc" sx={{fontSize:28, fontWeight: "900"}}>
            For Students, By Students
            </Typography>
        </Stack>
        <Box display="flex" 
            alignItems="center"
            justifyContent="left"
            mx="8vw"
            mt="30px"
            mb="15px"
            sx={{ color: 'common.white', fontSize: '15px'}}>
            <h3>
              <Typography className="desc" sx={{fontSize:15, fontWeight: 500}}>Need assistance with learning from textbooks?
                <br /><br />
                EZread has compiled textbook summaries written by vetted scholars
                 to help you study for your classes with high quality notes
                <br /><br />
                Textbooks are huge, intimidating, and difficult to study with. They
                have their place in education, but we wanted a more efficient
                way to cram in late night study sessions.
                <br /><br />
                Rishi, Alvin, and Aidan, our 3 founders, have worked to launch this
                service to help college students around the globe study for exams and assignments.
                <br /><br />
                Simply click on the <Link underline="hover" onClick={summaryHandler} sx= {{'&:hover': {cursor:"pointer"}}}>summaries</Link> tab, and browse through our collection
                to find a textbook that you need help with.
                <br /><br />
                If we are missing a textbook that you would like added, 
                    please fill out our feedback form by clicking on the Contact tab!
                    Feel free to use that form to submit any other questions or concerns you have
                <br /><br />
                If you'd like to join our team of qualified writers, click here to <Link underline="hover" onClick={applyHandler} sx= {{'&:hover': {cursor:"pointer"}}}>apply</Link>.
                <br /><br />
                </Typography>
            </h3>
        </Box>
      </>}
        
    </>
    );
  }
  
export default AboutScreen;
