import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import App from './App';
import { createRoot } from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/dist/js/bootstrap.bundle.min';
const root = createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId="249044590081-9tvj6j7hi0bsi5p6q661lkmhl1vsrctj.apps.googleusercontent.com">
    <Router>
      <App/>
    </Router>
  </GoogleOAuthProvider>
  ,
  document.getElementById('root')
);
