import React, {useState,useEffect} from 'react' ;
import { Box, Typography, useTheme, useMediaQuery, TextField, Collapse, Alert, Stack, Button } from '@mui/material';
import { useNavigate, useParams} from "react-router-dom";
import axios from 'axios';
import configuration from '../../config.json';

const VerifyEmailScreen = () => { 
    const theme = useTheme();
    const backendUrl = configuration.backendUrl;
    const navigate = useNavigate();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [code, setCode] = useState("");
    const {verifyToken}  = useParams();
    const [loading, setLoading] = useState(false);

    const verifyEmailHandler = async (e) => {
        e.preventDefault();
        if (code.length !== 6) {
            setError("Code must be 6 digits in length");
            return
        }

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        try {
            setLoading(true)
            const { data } = await axios.post(
                backendUrl+`/api/auth/verify-email`, 
                {verifyToken: verifyToken, code: code},
                config
            );

            setSuccess(data.data);
            setLoading(false)
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.error)
                setError(error.response.data.error);
            else if (error.message)
                setError(error.message);
            else
                setError("Something went wrong. Please try again later.");
        }
    };

    //eseEffect for error and success
    useEffect(() => {
        if (error.length > 0) {
            //scroll to top of page
            window.scrollTo(0, 0);
            setLoading(false);
            setTimeout(() => {
                setError("");
            }, 5000);
        }
        if (success.length > 0) {
            setTimeout(() => {
                setSuccess("");
                navigate("/login")
            }, 3000);
        }
    }, [error, success]);

    useEffect(() => {
        if (code.length > 6) {
            setCode(code.slice(0, 6));
        }
    }, [code]);

    return (
        <Box width={isNotMobile ? "40%" : "80%" } 
            p="3rem" 
            m="2rem auto" 
            mt={isNotMobile ? 18 : 14}
            mb={isNotMobile ? "2rem auto" : 18}
            borderRadius={5} 
            backgroundColor={theme.palette.background.alt} 
            sx={{boxShadow:5}}
        >
            <form onSubmit={verifyEmailHandler}>
                <Collapse in={error.length > 0}>
                    <Alert severity="error" onClose={() => {setError("")}}>{error}</Alert>
                </Collapse>
                
                <Collapse in={success.length > 0}>
                    <Alert severity="success" onClose={() => {setSuccess("")}}>{success}</Alert>
                </Collapse>
                <Typography variant="h3" fontWeight="bold" mb={3} textAlign="center">Verify Email</Typography>

                <Typography variant="body1" gutterBottom>Enter the 6 digit code sent to your email</Typography>

                <Stack direction="row" alignItems="center" sx={{mb: 1}} spacing={1}>
                    <TextField type="number" value={code} sx={{ width: 100}}
                    onChange={(e)=>{setCode(e.target.value)}} />
                    <Button type="submit" variant="contained" fullWidth size="large" 
                    sx={{py: 1.3, width: 100}} disabled={!code || loading}>
                        Verify
                    </Button>
                </Stack>

                
            </form>

        </Box>
    )
}

export default VerifyEmailScreen;