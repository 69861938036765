import React from "react";
import {Box, Typography, Button, Stack, useTheme, useMediaQuery} from "@mui/material";
import {Link} from "react-router-dom";
import aiTools from "../assets/ezreadAItools.webp";
import summarySvg from "../assets/summary.svg";
import screenshot from "../assets/sc1.png";
import stanfordDark from "../assets/schools/stanford_dark.webp";
import vandyDark from "../assets/schools/vandy_dark.webp";
import vtDark from "../assets/schools/vt_dark.webp";
import harvard from "../assets/schools/harvard.webp";
import michiganDark from "../assets/schools/michigan_dark.webp";
import fsuDark from "../assets/schools/fsu_dark.webp";
import Testimonials from "../common/Testimonials";
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import SpatialTrackingOutlinedIcon from '@mui/icons-material/SpatialTrackingOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import examIcon from "../assets/exam.webp";

const HomeScreen = () => {
    const theme = useTheme();
    const mobile = useMediaQuery('(max-width: 600px)');

    return (
        <Stack px="6vw" py="6vh" spacing='15vh'>
      <Stack direction="row" spacing='4vw'>
        <Stack spacing="4vh">
          <Typography variant={mobile ? "h4" : "h2"} fontWeight={700}>Simple & comprehensive<br/>
            summaries of your<br/>
            textbooks...<br/>
            All in one place!
          </Typography>
          <Typography variant={mobile ? "h6" : "h5"} >Scour through our library of summaries written by experts.</Typography>
          <Link to="/search/query=2000,2011,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022&subject=&publisher=&edition=&year=&page=1&sortBy=Default" style={{color: theme.palette.primary.main, textDecoration:"none"}}>
            <Button variant="contained" size="large" sx={{borderRadius: 1.75, py: 1.2, width: 170}}>Search now!</Button>
          </Link>
        </Stack>
        
        {!mobile && <img src={summarySvg} alt="summary" style={{width:"40%"}}/>}
      </Stack>

      <Box>
        <Typography textAlign="center" fontSize={mobile ? 18 : 20} fontWeight={500}>Trusted by over 20,000 students from:</Typography>
        {mobile ? 
            <Stack mt={2} spacing={.5}>
                <Stack direction="row" alignItems="center" sx={{dispaly:"flex", justifyContent: "center"}} spacing="5vw" width="100%">
                <img src={stanfordDark} alt="Stanford" style={{ width: '30vw' }} />
                <img src={vandyDark} alt="Vandy" style={{ width: '14vw'  }} />
                <img src={harvard} alt="Harvard" style={{ width: '14vw'  }} />
                </Stack>
                <Stack direction="row" alignItems="center" sx={{dispaly:"flex", justifyContent: "center"}} spacing="5vw" width="100%">
                <img src={michiganDark} alt="Michigan" style={{ width: '21vw' }} />
                <img src={fsuDark} alt="FSU" style={{ width: '14vw'  }} />
                <img src={vtDark} alt="VT" style={{ width: '23vw'  }} />
                </Stack>
            </Stack>
        : <Stack direction="row" alignItems="center" sx={{dispaly:"flex", justifyContent: "center"}} spacing="5vw" width="100%">
          <img src={stanfordDark} alt="Stanford" style={{ width: '16vw' }} />
          <img src={vandyDark} alt="Vandy" style={{ width: '8vw'  }} />
          <img src={harvard} alt="Harvard" style={{ width: '8vw'  }} />
          <img src={michiganDark} alt="Michigan" style={{ width: '12vw' }} />
          <img src={fsuDark} alt="FSU" style={{ width: '8vw'  }} />
          <img src={vtDark} alt="VT" style={{ width: '13vw'  }} />
        </Stack> }
      </Box>

      
      {mobile ? 
        <Box>
            <Stack direction="row" spacing="3vw" alignItems="end">
            <Box width="100%">
                <Typography variant={mobile ? "h4" : "h2"} fontWeight={700}>Understand textbooks better.</Typography>
                <Typography variant={mobile ? "h6" : "h5"} mt={1.5}> Retain more information from your textbooks with concise summaries of every chapter.</Typography>
                <Box sx={{"&:hover": {textDecoration: "underline", color: theme.palette.primary.main}}}>
                <Link to="/search/query=2000,2011,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022&subject=&publisher=&edition=&year=&page=1&sortBy=Default" style={{color: theme.palette.primary.main, textDecoration:"none"}}>
                    <Typography fontSize={mobile ? 16 : 20} mt={1.5} fontWeight={600}> Explore summaries →</Typography>
                </Link>
                </Box>
            </Box>
            </Stack>
            <Box component="img" boxShadow={2} src={screenshot} alt="screen shot" sx={{mt: '4vh', width:"100%", borderRadius: 5, border: '1px solid gray'}}/>
            <Stack spacing={2.5} width="100%" mt={3}>
                <Stack width="100%" direction="row" spacing={3}>
                    <AutoStoriesOutlinedIcon sx={{fontSize: 50}}/>
                    <Box>
                        <Typography fontWeight={700}>Expert summaries</Typography>
                        <Typography>Chapters condensed down to the most important concepts.</Typography>
                    </Box>
                </Stack>
                <Stack width="100%" direction="row" spacing={3}>
                    <SpatialTrackingOutlinedIcon sx={{fontSize: 50}}/>
                    <Box>
                        <Typography fontWeight={700}>Listen on the go</Typography>
                        <Typography>Listen to audio summaries on your walk to class.</Typography>
                    </Box>
                </Stack>
                <Stack width="100%" direction="row" spacing={3}>
                    <QuestionAnswerOutlinedIcon sx={{fontSize: 50}}/>
                    <Box>
                        <Typography fontWeight={700}>AI chatting</Typography>
                        <Typography>Ask questions and get answers from the chapter summary itself.</Typography>
                    </Box>
                </Stack>
            </Stack>
        </Box>
      : 
        <Box>
            <Stack direction="row" spacing="3vw" alignItems="end">
            <Box width="100%">
                <Typography variant={mobile ? "h4" : "h2"} fontWeight={700}>Understand textbooks better.</Typography>
                <Typography variant={mobile ? "h6" : "h5"} mt={1.5}> Retain more information from your textbooks with concise summaries of every chapter.</Typography>
                <Box sx={{"&:hover": {textDecoration: "underline", color: theme.palette.primary.main}}}>
                <Link to="/search/query=2000,2011,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022&subject=&publisher=&edition=&year=&page=1&sortBy=Default" style={{color: theme.palette.primary.main, textDecoration:"none"}}>
                    <Typography fontSize={mobile ? 16 : 20} mt={1.5} fontWeight={600}> Explore summaries →</Typography>
                </Link>
                </Box>
            </Box>
            <Stack direction="row" spacing={2.5} width="100%">
                <Box width="100%">
                <AutoStoriesOutlinedIcon sx={{fontSize: 70}}/>
                <Typography fontWeight={700}>Expert summaries</Typography>
                <Typography>Chapters condensed down to the most important concepts.</Typography>
                </Box>
                <Box width="100%">
                <SpatialTrackingOutlinedIcon sx={{fontSize: 70}}/>
                <Typography fontWeight={700}>Listen on the go</Typography>
                <Typography>Listen to audio summaries on your walk to class.</Typography>
                </Box>
                <Box width="100%">
                <QuestionAnswerOutlinedIcon sx={{fontSize: 70}}/>
                <Typography fontWeight={700}>AI chatting</Typography>
                <Typography>Ask questions and get answers from the chapter summary itself.</Typography>
                </Box>
            </Stack>
            </Stack>
            <Box component="img" boxShadow={2} src={screenshot} alt="screen shot" sx={{mt: '4vh', width:"100%", borderRadius: 5, border: '1px solid gray'}}/>
        </Box>
      }

      {mobile ? 
        <Box>
            <Stack direction="row" spacing="3vw" alignItems="end">
            <Box width="100%">
                <Typography variant={mobile ? "h4" : "h2"} fontWeight={700}>Study smarter.</Typography>
                <Typography variant={mobile ? "h6" : "h5"} mt={1.5}> Learn more efficiently with our AI study tools.</Typography>
                <Box sx={{"&:hover": {textDecoration: "underline", color: theme.palette.primary.main}}}>
                <Link to="/ai/flashcards" style={{color: theme.palette.primary.main, textDecoration:"none"}}>
                    <Typography fontSize={mobile ? 16 : 20} mt={1.5} fontWeight={600}> Explore AI study tools →</Typography>
                </Link>
                </Box>
            </Box>
            </Stack>
            <Box component="img" boxShadow={2} src={aiTools} alt="screen shot 2" sx={{mt: '4vh', width:"100%", borderRadius: 5, border: '1px solid gray'}}/>
            <Stack spacing={2.5} width="100%" mt={3}>
                <Stack width="100%" direction="row" spacing={3}>
                    <AutoStoriesOutlinedIcon sx={{fontSize: 50}}/>
                    <Box>
                        <Typography fontWeight={700}>Smart flashcards</Typography>
                        <Typography>Generate flashcards from your notes and uploaded material.</Typography>
                    </Box>
                </Stack>
                <Stack width="100%" direction="row" spacing={3}>
                    <img src={examIcon} alt="Mock exam" style={{height: '45px'}}/>
                    <Box pl='5px'>
                        <Typography fontWeight={700}>Mock exams</Typography>
                        <Typography>Take practice exams that pull questions straight from your notes.</Typography>
                    </Box>
                </Stack>
                <Stack width="100%" direction="row" spacing={3}>
                    <QuestionAnswerOutlinedIcon sx={{fontSize: 50}}/>
                    <Box>
                        <Typography fontWeight={700}>AI chatting</Typography>
                        <Typography>Ask questions and get answers from the chapter summary itself.</Typography>
                    </Box>
                </Stack>
            </Stack>
        </Box>
      : 
        <Box>
            <Stack direction="row" spacing="3vw" alignItems="end">
                <Box width="100%">
                <Typography variant={mobile ? "h4" : "h2"} fontWeight={700} sx={{width: 300}}>Study smarter.</Typography>
                <Typography variant={mobile ? "h6" : "h5"} mt={1.5} sx={{width: 300}}> Learn more efficiently with our AI study tools.</Typography>
                <Box sx={{"&:hover": {textDecoration: "underline", color: theme.palette.primary.main}}}>
                    <Link to="/ai" style={{color: theme.palette.primary.main, textDecoration:"none"}}>
                    <Typography fontSize={mobile ? 16 : 20} mt={1.5}> Explore AI study tools →</Typography>
                    </Link>
                </Box>
                </Box>
                <Stack direction="row" spacing={2.5} width="100%">
                <Box width="100%">
                    <QuizOutlinedIcon sx={{fontSize: 70}}/>
                    <Typography fontWeight={700}>Smart flashcards</Typography>
                    <Typography>Generate flashcards from your notes and uploaded material.</Typography>
                </Box>
                <Box width="100%">
                    <img src={examIcon} alt="Mock exam" style={{width: '60px'}}/>
                    <Box height='10px'></Box>
                    <Typography fontWeight={700}>Mock exams</Typography>
                    <Typography>Take practice exams that pull questions straight from your notes.</Typography>
                </Box>
                <Box width="100%">
                    <QuestionAnswerOutlinedIcon sx={{fontSize: 70}}/>
                    <Typography fontWeight={700}>AI chatting</Typography>
                    <Typography>Ask questions and get answers from the chapter summary itself.</Typography>
                </Box>
                </Stack>
            </Stack>
            <Box component="img" boxShadow={2} src={aiTools} alt="screen shot 2" sx={{mt: '4vh', width:"100%", borderRadius: 5, border: '1px solid gray'}}/>
        </Box>
      }

      <Box>
        <Typography variant={mobile ? "h4" : "h2"} fontWeight={700} mb={4} sx={{width: mobile ? '80%' : '540px'}}>What other students have to say:</Typography>
        <Testimonials spacing="10vw"/>
      </Box>

      <Stack spacing="4vh" pb="10vh">
          <Typography variant={mobile ? "h4" : "h2"} fontWeight={700}>Get started for free.</Typography>
          <Typography variant={mobile ? "h6" : "h5"}> Get 3 days free to ace your first quiz 😁</Typography>
          <Link to="/pricing" style={{color: theme.palette.primary.main, textDecoration:"none"}}>
            <Button variant="contained" size="large" sx={{borderRadius: 1.75, py: 1.2, width: 180}}>Start free trial</Button>
          </Link>
        </Stack>
    </Stack>
    );
};


export default HomeScreen;