import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageList from '@mui/material/ImageList';
import TextbookCard from '../common/TextbookCard';
import LoadingSpinner from '../common/LoadingSpinner';
import { Alert, Collapse} from '@mui/material';
import configuration from '../../config.json';

const Bookmarks = (Component) => {
    const navigate = useNavigate();
    const backendUrl = configuration.backendUrl;
    const scrollRef = React.useRef(null);
    const search = useLocation().search;
    const mobile = useMediaQuery("(max-width: 900px)");
    const tablet = useMediaQuery("(max-width: 1300px)");
    const columns = mobile ? 1 : tablet ? 2 : 3;
    const [textbooks, setTextbooks] = useState([[]]);
    const [pageCount, setPageCount] = React.useState(1);

    const [sortBy, setSortBy] = useState(new URLSearchParams(search).get('sortBy'));
    if (!sortBy || sortBy === "null") {
        setSortBy("Default");
    }
    const [page, setPage] = useState(1);
    const [openAlert, setOpenAlert] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log(page)
        const setVals = async () => {
            function getPaginatedItems(array, pageCount) {
                const itemsPerPage = 12;
                const startIndex = (pageCount - 1) * itemsPerPage;
                const endIndex = startIndex + itemsPerPage;
                return array.slice(startIndex, endIndex);
            }
              
            try {
                setLoading(true)
                const token = await axios.post(backendUrl+'/api/auth/refresh-token', {}, {withCredentials: true});
                if (token.data && token.data !== "Unauthorized") {
                    const config = {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token.data}`
                        },
                    };
                    const text = await axios.get(backendUrl+`/api/textbooks/bookmarks`, config);
                    setPageCount(text.data.pageCount);
                    //set textbooks to the data from the backend based on count. so if count = 1, show first 12 textbooks, if count = 2 then show next
                    setTextbooks(getPaginatedItems(text.data.results, page));
                } else {
                    navigate("");
                }

            } catch (err) {
                setError(err?.response?.data?.error);
                console.log(err)
            } finally {
                setLoading(false);
            }
        };
        setVals();
    }, [page]);

    const handlePageChange = (event, newPage)  => {
        console.log(newPage);
        setPage(newPage);
        //navigate(`/search/query=${query}&subject=${subject}&publisher=${publisher}&edition=${edition}&year=${year}&page=${newPage}&sortBy=${sortBy}`);
        if (scrollRef.current !== null) {
            (scrollRef.current).scrollTop = 0;
        }
    };

  return (
    <Box>
        
        <Collapse in={openAlert}>
            <Alert severity="warning" variant="filled" onClose={() => {setOpenAlert(false);}} >
                Please <strong>login</strong> to bookmark a textbook! 
            </Alert>
        </Collapse>
        <Collapse in={error !== ""}>
            <Alert severity="error" variant="filled" onClose={() => {setError("");}} >
                {error}
            </Alert>
        </Collapse>
        
        <Typography fontSize="32px" fontWeight="bold" sx={{ p: 0, ml: "3vw", mt:"3vh", mb: 1, color:"#fff"}}>Bookmarks:</Typography>
        {loading ? <LoadingSpinner text={'Loading summaries...'} /> : 
        <Stack py={0.5} px={3} spacing={4} sx={{overflowY: 'auto', height: 'calc(100vh - 150px)'}} ref={scrollRef}>
            <Box width='100%' display='flex' justifyContent='center'  sx={{ flexGrow: 1}} mt={3}>
                {textbooks && textbooks?.length > 0 ? <ImageList cols={columns} rowHeight={300} gap={30}>
                    {textbooks?.map((textbook) => (
                        <TextbookCard key={textbook._id} id={textbook._id} title={textbook.title} year={textbook.year} authors={textbook.authors} edition={textbook.edition} publisher={textbook.publisher} isbn={textbook.isbn} cover={textbook.cover} numChapters={textbook.chapters.length} chapters={textbook.chapters} />
                    )) }
                </ImageList>
                :
                (loading ? <LoadingSpinner text='Loading summaries...'/> :

                <Stack spacing={2} alignItems="center" width='500px' mt={3} mb='40vh'>
                    <Typography fontSize={mobile ? 20 : 24} fontWeight={700} textAlign='center'>Looks like you don't have anything bookmarked...</Typography>
                    <Typography fontSize={mobile ? 16 : 20} fontWeight={400} textAlign='center'>Click on the bookmark icon on the top-right of a textbook card to populate this page!</Typography>
                    <Link to={configuration.search} style={{textDecoration: 'none'}}>
                        <Button variant="contained" >Summaries page</Button>
                    </Link>
                </Stack> )
            }
            </Box>
        </Stack>}
        {textbooks?.length > 0  && 
            <Box display="flex" justifyContent="center" mt={4}>
                <Pagination
                    onChange={handlePageChange}
                    page={page}
                    color="primary" 
                    variant = "outlined"
                    shape="rounded" 
                    count={pageCount}
                    defaultPage={1}
                />
            </Box>
        }
    </Box>
  );
};

export default Bookmarks;