import React from 'react' ;
import { Box, Typography, useTheme, useMediaQuery, Collapse, Alert, TextField, Button, Divider } from '@mui/material';
import { useState, useEffect } from 'react';
import axios from 'axios';
import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate, Link } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import configuration from '../../config.json';
//import Cookies from 'js-cookie';

const LoginScreen = () => { 
    const theme = useTheme();
    const backendUrl = configuration.backendUrl;
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const mobile = useMediaQuery("(max-width: 600px)");
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const config = {
        headers: { "Content-Type": "application/json" }, 
        withCredentials: true
    }

    const loginHandler = async (e) => { 
        e.preventDefault();

        try {
            const { data } = await axios.post(backendUrl+"/api/auth/login", {email, password}, config);
            //console.log('data', data)
            if (data.accessToken) {
                localStorage.setItem("authToken", true);
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${data.accessToken}`
                    },
                    withCredentials: true
                };
                const usr = await axios.get(backendUrl+`/api/auth/subscription`, config);

                if (usr.data) {
                    localStorage.setItem("sub", usr.data);
                } else {
                    localStorage.setItem("sub", "none");
                }
                let nav = "/search/query=2000,2011,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022&subject=&publisher=&edition=&year=&page=1&sortBy=Default";
                //await axios.get("/api/auth/admin", config).then(res => {localStorage.setItem("admin", res.data.admin); nav="/admin" });
                navigate(nav);
                //window.location.reload();
            }
        } catch (err) {
            console.log(err);
            if (err?.response?.data?.error) {
                setError(err.response.data.error);
            } else if (err?.message) {
                setError(err.message);
            } else {
                setError("Something went wrong. Please try again later.");
            }

        }
    }

    const [ user, setUser ] = useState("");

    const googleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => {setUser(codeResponse.access_token);},
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(
        () => {
            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user}`, {
                        headers: {
                            Authorization: `Bearer ${user}`,
                            Accept: 'application/json'
                        }
                    })
                    .then(async (res) => {
                        const google_email = res.data.email;
                        const verified_email = res.data.verified_email;
                        const { data } = await axios.post(backendUrl+"/api/auth/google-login", {google_email, verified_email}, config);
                        if (data.accessToken) {
                            localStorage.setItem("authToken", true);
                            const config = {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${data.accessToken}`
                                },
                            };
                            const usr = await axios.get(backendUrl+`/api/auth/subscription`, config);
                            if (usr.data) {
                                localStorage.setItem("sub", usr.data);
                            } else {
                                localStorage.setItem("sub", "none");
                            }
                            console.log("sub", localStorage.getItem("sub"));
                            let nav = configuration.search;
                            //await axios.get("/api/auth/admin", config).then(res => {localStorage.setItem("admin", res.data.admin); nav="/admin" });
                            //await axios.get("/api/auth/partner", config).then(res => {localStorage.setItem("partner", res.data.partner); nav="/partner" });
                            navigate(nav);
                            //window.location.reload();
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        if (err.response.data.error) {
                            setError(err.response.data.error);
                        } else {
                            setError(err.message);
                        }
                    });
            }
        },
        [ user ]
    );

    //useEffect for error
    useEffect(() => {
        if (error) {
            if (!error.includes("verify")) {
                setTimeout(() => {
                    setError("");
                }, 5000);
            }
        }
    }, [error]);

    const resendEmail = async (e) => {
        e.preventDefault();
        try {
            await axios.post(backendUrl+'/api/auth/resend-verification', {email: email})
        } catch (error) {
            console.log(error)
            setError("Error resending verification email. Please try again later.");
        }
    }


    return (
        <Box width={isNotMobile ? "32%" : "80%" } 
            p="3rem" 
            m="2rem auto" 
            my={isNotMobile ? 10 : 8}
            borderRadius={5} 
            bgcolor={theme.palette.background.paper} 
            sx={{boxShadow:5}}
        >
            <Collapse in={error} sx={{cursor: "pointer"}} onClick={()=>setError("")}>
                { error.includes("verify") ? 
                    <Alert severity="error" sx={{mb:2}} onClick={resendEmail}>{error}. <b>Click here</b> to resend verification email</Alert> :
                    <Alert severity="error" sx={{mb:2}}>{error}</Alert>
                }
                </Collapse>
            <form onSubmit={loginHandler}>
                <Typography variant={mobile ? "h4" : "h3"} fontWeight="bold" mb={3} textAlign="center">Sign In</Typography>
                <Button variant="outlined" size="large" fullWidth sx={{py: 1.3}}
                    startIcon={<GoogleIcon/>}
                    onClick={() => googleLogin()}
                >
                    Sign in with Google
                </Button>

                <Divider sx={{mt: mobile ? 2 : 3, mb: mobile ? 1 : 2}}> <Typography fontWeight="bold" color="grey">OR</Typography> </Divider>

                <Typography variant="h6" fontWeight={500} mb={1}>Email</Typography>
                <TextField sx={{".MuiOutlinedInput-root": { boxShadow: 1 }, mb: mobile ? 1.5 : 2}} size={mobile ? "small" : "medium"}
                required fullWidth name="email" value={email} onChange={(e) => setEmail(e.target.value)}/>

                <Typography variant="h6" fontWeight={500} mb={1}>Password</Typography>
                <TextField name="password" type="password" sx={{".MuiOutlinedInput-root": { boxShadow: 1 }, mb: mobile ? 3 : 4}} size={mobile ? "small" : "medium"}
                required fullWidth value={password} onChange={(e) => setPassword(e.target.value)}/>
                { /* <Button fullWidth variant="contained" type="submit" size="large" sx={{color:"white", my:2}}>Log in</Button> */ }
                <Button type="submit" variant="contained" fullWidth size={mobile ? "medium" : "large"} sx={{py: mobile ? 1 : 1.3}}>Log in</Button>

                <Divider sx={{my: mobile ? 2.5 : 4}}></Divider>
            </form>
            <Typography mt={2} textAlign="center">Don't have an account? <Link style={{color: theme.palette.primary.main}} to="/signup"><b>Sign up</b></Link></Typography>
            <Typography mt={2} textAlign="center"> <Link to="/forgot-password" style={{color:theme.palette.primary.main }}>Forgot your password?</Link></Typography>
        </Box>
    )
}

export default LoginScreen;