import { useMediaQuery, Stack } from '@mui/material';
import TestimonialCard from './TestimonialCard';

export default function Testimonials({spacing="3vw"}) {
    const tablet = useMediaQuery('(max-width: 1080px)');

    return (
        <Stack direction={tablet ? "column" : "row"} alignItems="center" spacing={tablet ? '5vh' : spacing}>
            <TestimonialCard 
                src="https://i.imgur.com/gvZl08u.jpg" 
                alt="James Testimonial" 
                quote="Because of EZread, I was able to spend less time re-reading textbooks and more time hanging out with friends."
                fName="James" 
                school="Stanford"
                year="23" 
            />
            <TestimonialCard 
                src="https://ezread.io/natalia.webp" 
                alt="Natalia Testimonial" 
                quote="With EZread summaries, I'm able to get a good night's rest before my exam instead of stressing over self-studying my textbooks."
                fName="Natalia" 
                school="Emory"
                year="25" 
            />
            <TestimonialCard 
                src="https://ezread.io/rohan.webp" 
                alt="Rohan Testimonial" 
                quote="I was able to ace every one of my Neuroscience exams using EZread's textbook summaries. It made the studying so much easier."
                fName="Rohan" 
                school="Vanderbilt"
                year="24" 
            />
        </Stack>
    );
}


