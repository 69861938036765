import { Box, Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import {Link} from 'react-router-dom';
import logo from '../assets/logo.svg';
import configuration from '../../config.json';

const curYear = new Date().getFullYear();

export default function Footer() {
    const mobile = useMediaQuery('(max-width: 600px)');
    const tablet = useMediaQuery('(max-width: 1000px)');
  return (
      <footer>
        <Divider sx={{my: '6vh'}}/>
        <Stack px='5vw'>
            <Stack direction={tablet ? "column" : "row"} spacing="10vw" sx={{mb: 5}} alignItems={"start"}>
                <Link to="" style={{textDecoration: 'none'}}>
                    <Stack direction="row" alignItems="center">
                    <img src={logo} alt="logo" width="54" height="54" />
                    <Box p={1} borderRadius={2} variant="h4" component={Typography} sx={{fontWeight: 500, color: "white"}}>
                        EZread
                    </Box>
                    </Stack>
                </Link>
                {tablet && !mobile &&
                    <Stack direction="row" spacing="30vw">
                        <Stack spacing={1}>
                            <Typography variant="h6" fontWeight={700} sx={{pb: 1}}> Company </Typography>
                            <Link to={`/about`} style={{textDecoration: 'none'}}>
                                <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                                About
                                </Box>
                            </Link>
                            <Link to={`https://forms.gle/b5Zj51kKFeQcLbHq7`} target="_blank" style={{textDecoration: 'none'}}>
                                <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                                Contact
                                </Box>
                            </Link>
                            <Link to={`/faq`} style={{textDecoration: 'none'}}>
                                <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                                FAQ
                                </Box>
                            </Link>
                            <Link to={`/pricing`} style={{textDecoration: 'none'}}>
                                <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                                Pricing
                                </Box>
                            </Link>
                        </Stack>

                        <Stack spacing={1}>
                            <Typography variant="h6" fontWeight={700} sx={{pb: 1}}> Products </Typography>
                            <Link to={configuration.search} style={{textDecoration: 'none'}}>
                                <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                                Summaries
                                </Box>
                            </Link>
                            <Link to={`/ai/flashcards`} style={{textDecoration: 'none'}}>
                                <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                                AI Flashcards
                                </Box>
                            </Link>
                            <Link to={`/ai/quiz`} style={{textDecoration: 'none'}}>
                                <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                                AI Mock Exams
                                </Box>
                            </Link>
                        </Stack>
                    </Stack> 
                }
                {tablet && !mobile &&
                    <Stack direction="row" spacing="30vw">
                        <Stack spacing={1}>
                            <Typography variant="h6" fontWeight={700} sx={{pb: 1}}> Socials </Typography>
                            <Link to={`https://www.instagram.com/ezread.io/`} target="_blank" style={{textDecoration: 'none'}}>
                                <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                                Instagram
                                </Box>
                            </Link>
                            <Link to={`https://www.linkedin.com/company/79508710/`} target="_blank"  style={{textDecoration: 'none'}}>
                                <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                                LinkedIn
                                </Box>
                            </Link>
                            <Link to={`https://www.tiktok.com/@ezread.io?lang=en`} target="_blank"  style={{textDecoration: 'none'}}>
                                <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                                Tiktok
                                </Box>
                            </Link>
                            <Link to={`https://x.com/EZreadLLC/`} target="_blank" style={{textDecoration: 'none'}}>
                                <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                                Twitter
                                </Box>
                            </Link>
                        </Stack>

                        <Stack spacing={1}>
                            <Typography variant="h6" fontWeight={700} sx={{pb: 1}}> Business </Typography>
                            <Link to={`https://ezread.io/TOS.pdf`} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                                <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                                Terms of Use
                                </Box>
                            </Link>
                            <Link to={`https://ezread.io/Privacy_Policy.pdf`} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                                <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                                Privacy
                                </Box>
                            </Link>
                            <Link to={`https://forms.gle/AMu1JyNEAgPGp8rc6`} target="_blank"  style={{textDecoration: 'none'}}>
                                <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                                DMCA Removal
                                </Box>
                            </Link>
                        </Stack>
                    </Stack>
                }
                {(!tablet || mobile) && <Stack spacing={1}>
                    <Typography variant="h6" fontWeight={700} sx={{pb: 1}}> Company </Typography>
                    <Link to={`/about`} style={{textDecoration: 'none'}}>
                        <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                        About
                        </Box>
                    </Link>
                    <Link to={`https://forms.gle/b5Zj51kKFeQcLbHq7`} target="_blank" style={{textDecoration: 'none'}}>
                        <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                        Contact
                        </Box>
                    </Link>
                    <Link to={`/faq`} style={{textDecoration: 'none'}}>
                        <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                        FAQ
                        </Box>
                    </Link>
                    <Link to={`/pricing`} style={{textDecoration: 'none'}}>
                        <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                        Pricing
                        </Box>
                    </Link>
                </Stack>}
                {(!tablet || mobile) && <Stack spacing={1}>
                    <Typography variant="h6" fontWeight={700} sx={{pb: 1}}> Products </Typography>
                    <Link to={configuration.search} style={{textDecoration: 'none'}}>
                        <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                        Summaries
                        </Box>
                    </Link>
                    <Link to={`/ai/flashcards`} style={{textDecoration: 'none'}}>
                        <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                        AI Flashcards
                        </Box>
                    </Link>
                    <Link to={`/ai/quiz`} style={{textDecoration: 'none'}}>
                        <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                        AI Mock Exams
                        </Box>
                    </Link>
                </Stack>}
                {(!tablet || mobile) && <Stack spacing={1}>
                    <Typography variant="h6" fontWeight={700} sx={{pb: 1}}> Socials </Typography>
                    <Link to={`https://www.instagram.com/ezread.io/`} target="_blank" style={{textDecoration: 'none'}}>
                        <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                        Instagram
                        </Box>
                    </Link>
                    <Link to={`https://www.linkedin.com/company/79508710/`} target="_blank"  style={{textDecoration: 'none'}}>
                        <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                        LinkedIn
                        </Box>
                    </Link>
                    <Link to={`https://www.tiktok.com/@ezread.io?lang=en`} target="_blank"  style={{textDecoration: 'none'}}>
                        <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                        Tiktok
                        </Box>
                    </Link>
                    <Link to={`https://x.com/EZreadLLC/`} target="_blank" style={{textDecoration: 'none'}}>
                        <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                        Twitter
                        </Box>
                    </Link>
                </Stack>}
                {(!tablet || mobile) &&<Stack spacing={1}>
                    <Typography variant="h6" fontWeight={700} sx={{pb: 1}}> Business </Typography>
                    <Link to={`https://ezread.io/TOS.pdf`} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                        <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                        Terms of Use
                        </Box>
                    </Link>
                    <Link to={`https://ezread.io/Privacy_Policy.pdf`} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                        <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                        Privacy
                        </Box>
                    </Link>
                    <Link to={`https://forms.gle/AMu1JyNEAgPGp8rc6`} target="_blank"  style={{textDecoration: 'none'}}>
                        <Box component={Typography} sx={{fontWeight: 500, color: "white", ":hover": {opacity: 0.7}}}>
                        DMCA Removal
                        </Box>
                    </Link>
                </Stack>}
            </Stack>
            <Typography>Copyright © {curYear} EZread LLC</Typography>
            <Box height='5vh'/>
        </Stack>
      </footer>
  );
}
