import { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, Stack, IconButton, CardHeader, Alert, Collapse, useMediaQuery } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { Link } from 'react-router-dom';
import config from '../../config.json';

const TextbookCard = ({ id, title, year, authors, edition, publisher, isbn, numChapters, cover, chapters }) => {
    const backendUrl = config.backendUrl;
    const mobile = useMediaQuery('(max-width: 600px)');
    const [openAlert, setOpenAlert] = useState(false);
    const [filled, setFilled] = useState(false);

    useEffect(() => {
        // Load bookmarks on component mount
        const loadBookmarks = async () => {
            try {
                const res = await axios.post(`${backendUrl}/api/auth/refresh-token`, {}, { withCredentials: true });
                const token = res.data;
                if (token) {
                    const config = {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    };
                    const response = await axios.get(`${backendUrl}/api/textbooks/user/${id}`, config);
                    if (response.data.bookmarks.includes(id)) {
                        setFilled(true);
                    }
                }
            } catch (err) {
                console.error("Error loading bookmarks:", err);
            }
        };

        loadBookmarks();
    }, [backendUrl, id]);

    const refreshToken = async () => {
        try {
            const res = await axios.post(`${backendUrl}/api/auth/refresh-token`, {}, { withCredentials: true });
            return res.data;
        } catch (err) {
            console.error("Error refreshing token:", err);
            return null;
        }
    };

    const handleBookmark = async () => {
        if (localStorage.getItem("sub") === "none") {
            setOpenAlert(true);
            setTimeout(() => setOpenAlert(false), 2000);
            return;
        }
        const token = await refreshToken();
        if (token) {
            try {
                await axios.post(
                    `${backendUrl}/api/textbooks/bookmark/${id}`,
                    {},
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setFilled(true);
            } catch (err) {
                console.error("Error creating bookmark:", err);
            }
        } else {
            setOpenAlert(true);
            setTimeout(() => setOpenAlert(false), 3000);
        }
    };

    const handleUnbookmark = async () => {
        if (localStorage.getItem("sub") === "none") {
            setOpenAlert(true);
            setTimeout(() => setOpenAlert(false), 3000);
            return;
        }
        const token = await refreshToken();
        if (token) {
            try {
                await axios.delete(`${backendUrl}/api/textbooks/bookmark/${id}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });
                setFilled(false);
            } catch (err) {
                console.error("Error removing bookmark:", err);
            }
        } else {
            setOpenAlert(true);
            setTimeout(() => setOpenAlert(false), 2000);
        }
    };

    const truncEnd = mobile ? 22 : 23;
    const authorNum = mobile ? 1 : 2;
    const truncatedTitle = title.length > 24 ? `${title.slice(0, truncEnd)}...` : title;
    const truncatedAuthors = authors.length > authorNum ? [...authors.slice(0, authorNum), '...'] : authors;

    return (
        <Card sx={{ height: 304, width: mobile ? 336 : 390, bgcolor: "#1a2e44", boxShadow: '0 3px 10px rgb(0 0 0 / 0.4)' }}>
            <CardHeader
                disableTypography
                title={
                    <Link to={`/summary/${chapters[0]}`} state={{ chapters }} style={{ textDecoration: "none" }}>
                        <Typography variant={mobile ? "h6" : "h5"} color="#fff" fontWeight="bold">
                            {truncatedTitle}
                        </Typography>
                    </Link>
                }
                action={
                    <IconButton
                        onClick={filled ? handleUnbookmark : handleBookmark}
                        aria-label="add to favorites"
                        sx={{ ml: 2, color: "#c4c4c4" }}
                    >
                        {filled ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                    </IconButton>
                }
            />
            <Collapse in={openAlert}>
                <Alert severity="warning" sx={{ mb: 2 }} variant="filled" onClose={() => setOpenAlert(false)}>
                    Please purchase a <Link to="/pricing" style={{color: 'white'}}>subscription</Link> to bookmark!
                </Alert>
            </Collapse>
            <Link to={`/summary/${chapters[0]}`} state={{ chapters }} style={{ textDecoration: "none" }}>
                <CardActionArea>
                    <CardContent sx={{ pt: 0 }}>
                        <Stack direction="row" spacing={2}>
                            <img src={cover} alt="cover" width={mobile ? "135px" : "150px"} height={mobile ? "198px" : "220px"} style={{ borderRadius: '5px' }} />
                            <Box>
                                <Typography fontSize={mobile ? 15 : 16} color="#e1e1ea">
                                    Author(s): <em>{truncatedAuthors.join(", ")}</em>
                                </Typography>
                                <Typography fontSize={mobile ? 15 : 16} color="#e1e1ea">
                                    Publisher: <em>{publisher}</em>
                                </Typography>
                                <Typography fontSize={mobile ? 15 : 16} color="#e1e1ea">
                                    Edition: <em>{edition}</em>
                                </Typography>
                                <Typography fontSize={mobile ? 15 : 16} color="#e1e1ea">
                                    Year: <em>{year}</em>
                                </Typography>
                                <Typography fontSize={mobile ? 15 : 16} color="#e1e1ea">
                                    ISBN: <em>{isbn}</em>
                                </Typography>
                                <Typography fontSize={mobile ? 15 : 16} color="#e1e1ea">
                                    Number of Chapters: <em>{numChapters}</em>
                                </Typography>
                            </Box>
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Link>
        </Card>
    );
};

export default TextbookCard;
