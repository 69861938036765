import * as React from 'react';
import { Box, Stack, useMediaQuery, Typography, Button, Collapse, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {BookmarkBorderOutlined, BookmarkAddOutlined, BookmarkAddedOutlined} from '@mui/icons-material';
import Testimonials from '../common/Testimonials';
import stanfordDark from "../assets/schools/stanford_dark.webp";
import vandyDark from "../assets/schools/vandy_dark.webp";
import vtDark from "../assets/schools/vt_dark.webp";
import harvard from "../assets/schools/harvard.webp";
import michiganDark from "../assets/schools/michigan_dark.webp";
import fsuDark from "../assets/schools/fsu_dark.webp";
import axios from 'axios';
import configuration from '../../config.json';

const pricingColor = "#1e2f45";

const Pricing = () => {
    const mobile = useMediaQuery("(max-width: 950px)");
    const tablet = useMediaQuery("(max-width: 1200px)");
    const theme = useTheme();
    const backendUrl = configuration.backendUrl;
    const primaryColor = theme.palette.primary.main;
    const [checked, setChecked] = React.useState(true);
    const [basicPrice, setBasicPrice] = React.useState(8);
    const [proPrice, setProPrice] = React.useState(13);
    const [premiumPrice, setPremiumPrice] = React.useState(30);
    const [alertType, setAlertType] = React.useState("basic");
    const [alert, setAlert] = React.useState("");

    const handleSwitch = () => {
        setChecked(!checked);
        if (checked) {
            setBasicPrice(9);
            setProPrice(15);
            setPremiumPrice(35);
        } else {
            setBasicPrice(8);
            setProPrice(13);
            setPremiumPrice(30);
        }
    };

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const token = await axios.post(backendUrl+'/api/auth/refresh-token', {}, {withCredentials: true});
    
                if (token.data && token.data !== "Unauthorized") {
                    const config = {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token.data}`
                        },
                    };
                    const usr = await axios.get(backendUrl+`/api/auth/subscription`, config);
                    if (usr.data) {
                        localStorage.setItem("sub", usr.data);
                    }
                    setTimeout(async () => {
                        const {data} = await axios.get(backendUrl+`/api/auth/subscription`, config);
                        if (data) {
                            localStorage.setItem("sub", usr.data);
                        }
                    }, 5000);
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, []);

    const basicHandler = async (e) => {
        e.preventDefault();
        try {
            setAlertType("basic");
            const token = await axios.post(backendUrl+'/api/auth/refresh-token', {}, {withCredentials: true});
    
            if (token.data && token.data !== "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token.data}`
                    },
                };
                const {data} = await axios.get(backendUrl+`/api/auth/user`, config);
                const type = checked ? "quarterly" : "monthly";
                if (!data.subscription) {
                    const session = await axios.post(backendUrl+'/api/stripe/checkout', {userId: data._id, sub: "basic", type: type});
                    console.log(session)
                    window.open(session.data.url, "_self")
                } else if (data.subscription) {
                    if (data.customerId) {
                        const portalSession = await axios.post(backendUrl+'/api/stripe/customer-portal', {'customerId': data.customerId,});
                        window.open(portalSession.data.url, "_self")
                    } else {
                        setAlert("Error accessing your subscription, please contact support!");
                    }
                }
            } else {
                setAlert("Please login to purchase a subscription!");
            }
        } catch (error) {
            console.log(error);

            if (error?.response?.data?.url) {
                window.open(error.response.data.url, "_self");
            } else if (error?.response?.data?.error) {
                setAlert(error.response.data.error);
            } else if (error?.message) {
                setAlert(error.message);
            } else {
                setAlert("An error occurred. Please try again later.");
            }
        }
    }

    const proHandler = async (e) => {
        e.preventDefault();
        try {
            setAlertType("pro");
            const token = await axios.post(backendUrl+'/api/auth/refresh-token', {}, {withCredentials: true});
    
            if (token.data && token.data !== "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token.data}`
                    },
                };
                const {data} = await axios.get(backendUrl+`/api/auth/user`, config);
                const type = checked ? "quarterly" : "monthly";
                if (!data.subscription) {
                    const session = await axios.post(backendUrl+'/api/stripe/checkout', {userId: data._id, sub: "pro", type: type});
                    console.log(session)
                    window.open(session.data.url, "_self")
                } else if (data.subscription) {
                    if (data.customerId) {
                        const portalSession = await axios.post(backendUrl+'/api/stripe/customer-portal', {'customerId': data.customerId,});
                        window.open(portalSession.data.url, "_self")
                    } else {
                        setAlert("Error accessing your subscription, please contact support!");
                    }
                } 
            } else {
                setAlert("Please login to purchase a subscription!");
            }
        } catch (error) {
            console.log(error);

            if (error?.response?.data?.url) {
                window.open(error.response.data.url, "_self");
            } else if (error?.response?.data?.error) {
                setAlert(error.response.data.error);
            } else if (error?.message) {
                setAlert(error.message);
            } else {
                setAlert("An error occurred. Please try again later.");
            }
        }
    }

    const premiumHandler = async (e) => {
        e.preventDefault();
        try {
            setAlertType("premium");
            const token = await axios.post(backendUrl+'/api/auth/refresh-token', {}, {withCredentials: true});
    
            if (token.data && token.data !== "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token.data}`
                    },
                };
                const {data} = await axios.get(backendUrl+`/api/auth/user`, config);
                const type = checked ? "quarterly" : "monthly";
                if (!data.subscription) {
                    const session = await axios.post(backendUrl+'/api/stripe/checkout', {userId: data._id, sub: "premium", type: type});
                    console.log(session)
                    window.open(session.data.url, "_self")
                } else if (data.subscription) {
                    if (data.customerId) {
                        const portalSession = await axios.post(backendUrl+'/api/stripe/customer-portal', {'customerId': data.customerId,});
                        window.open(portalSession.data.url, "_self")
                    } else {
                        setAlert("Error accessing your subscription, please contact support!");
                    }
                } 
            } else {
                setAlert("Please login to purchase a subscription!");
            }
        } catch (error) {
            console.log(error);

            if (error?.response?.data?.url) {
                window.open(error.response.data.url, "_self");
            } else if (error?.response?.data?.error) {
                setAlert(error.response.data.error);
            } else if (error?.message) {
                setAlert(error.message);
            } else {
                setAlert("An error occurred. Please try again later.");
            }
        }
    }

    return (
        <Stack alignItems="center" spacing={2} px={mobile ? '8vw' : '6vw'} pb={mobile ? 2 : 6} pt={mobile ? 6 : 8}>
           
                <Typography variant={mobile ? "h3" : "h2"} align="center" lineHeight={0.98} fontWeight={500}>
                    Explore EZread Pricing Plans.
                </Typography>
                <Typography variant={mobile ? "h5" : "h4"} align="center" gutterBottom fontWeight={500}>
                    The smartest way to study
                </Typography>

            {!mobile && <Stack direction="row" alignItems="center" sx={{dispaly:"flex", justifyContent: "center"}} spacing="5vw" width="100%" py="3vh">
                <img src={stanfordDark} alt="Stanford" style={{ width: '8vw' }} />
                <img src={vandyDark} alt="Vandy" style={{ width: '4vw'  }} />
                <img src={harvard} alt="Harvard" style={{ width: '4vw'  }} />
                <img src={michiganDark} alt="Michigan" style={{ width: '5.2vw' }} />
                <img src={fsuDark} alt="FSU" style={{ width: '4vw'  }} />
                <img src={vtDark} alt="VT" style={{ width: '5.2vw'  }} />
                </Stack>
            }

            <Box width="100%" display="flex" justifyContent="center" alignItems="center" pt="2vh">
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    bgcolor={pricingColor}
                    borderRadius={5}
                    py={0.8}
                    px={1.5}
                >
                    <Box
                        borderRadius={4}
                        py={0.5}
                        px={1}
                        sx={{ bgcolor: checked ? "inherit" : "#354f6e", cursor: "pointer" }}
                        onClick={handleSwitch}
                    >
                        <Typography color={!checked ? "inherit" : "gray"} fontWeight="bold">
                            Pay Monthly
                        </Typography>
                    </Box>
                    <Stack
                        borderRadius={4}
                        py={0.5}
                        px={1}
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ bgcolor: checked ? "#354f6e" : "inherit", cursor: "pointer" }}
                        onClick={handleSwitch}
                    >
                        <Typography color={checked ? "inherit" : "gray"} fontWeight="bold">
                            Pay Quarterly
                        </Typography>
                        <Typography fontWeight={600} sx={{ color: !checked ? "gray" : theme.palette.secondary.main, fontSize: 13 }}>
                            Save 12%
                        </Typography>
                    </Stack>
                </Stack>
            </Box>

            <Stack
                direction={mobile ? "column" : "row"}
                spacing={mobile ? '4vh' : '2vw'}
                alignItems={mobile ? "center" : "start"}
                pt={1}
            >
                <Box
                    bgcolor={pricingColor}
                    borderRadius={4}
                    px={4}
                    py={3}
                    flex={1}
                    width={mobile ? "100%" : "28vw"}
                    minHeight={tablet ? 500 : (mobile ? 320 : 440)}
                >
                    <Collapse in={alert.length > 0 && alertType==="basic"}>
                        <Alert severity="error" sx={{mb:2}}>{alert}</Alert>
                    </Collapse>

                     <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography fontWeight="bold" fontSize={18}>
                            Basic
                        </Typography>
                        <BookmarkBorderOutlined />
                    </Stack>
                    <Typography fontWeight="bold" fontSize={18}>
                        <span style={{ fontSize: "36px" }}>${basicPrice}</span>/mo
                    </Typography>
                    <Button
                        variant="contained"
                        disableElevation
                        fullWidth
                        sx={{ bgcolor: "#1875d1", color: "white", mt: 1.5, mb: 1.5, borderRadius: 1.5, textTransform: "none", fontSize: 16 }}
                        onClick={basicHandler}
                    >
                        {localStorage.getItem("sub") === "premium" || localStorage.getItem("sub") === "pro" ? "Downgrade" : 
                        (localStorage.getItem("sub") === "basic" ? "Billing Portal" : "Start for free")}
                    </Button>
                    <Box height={18} />
                    <ul style={{ padding: "0px 14px 0px 14px" }}>
                        <li style={{ marginBottom: "6px" }}>Access chapter summaries belonging to a single textbook</li>
                        <li style={{ marginBottom: "6px" }}>Access to bookmarks</li>
                        <li style={{ marginBottom: "6px" }}>15 AI-generated flashcard decks per month</li>
                        <li style={{ marginBottom: "6px" }}>15 AI-generated mock exams per month</li>
                    </ul>
                </Box>
                <Box
                    bgcolor={pricingColor}
                    borderRadius={4}
                    px={4}
                    py={3}
                    flex={1}
                    width={mobile ? "100%" : "28vw"}
                    minHeight={tablet ? 500 : (mobile ? 320 : 440)}
                >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography fontWeight="bold" fontSize={18}>
                            Pro
                        </Typography>
                        <BookmarkAddOutlined />
                        <Box borderRadius={5} bgcolor={"#5f62e8"} px={1}>
                            <Typography fontSize={12} fontWeight={700}>
                                Popular
                            </Typography>
                        </Box>
                    </Stack>
                    <Typography fontWeight="bold" fontSize={18}>
                        <span style={{ fontSize: "36px" }}>${proPrice}</span>/mo
                    </Typography>
                    <Button
                        color="secondary"
                        variant="contained"
                        disableElevation
                        fullWidth
                        onClick={proHandler}
                        sx={{ color: "black", mt: 1.5, mb: 4, borderRadius: 1.5, textTransform: "none", fontSize: 16 }}
                    >
                        {localStorage.getItem("sub") === "premium" ? "Downgrade" : 
                        (localStorage.getItem("sub") === "pro" ? "Billing Portal" : 
                        (localStorage.getItem("sub") === "basic" ? "Upgrade" : "Start for free")
                        )}
                    </Button>
                    <Typography fontWeight="bold" lineHeight={1} mb={1}>
                        Everything in Basic +
                    </Typography>
                    <ul style={{ padding: "0px 14px 0px 14px" }}>
                        <li style={{ marginBottom: "6px" }}>Unlimited access to all EZread textbook chapter summaries</li>
                        <li style={{ marginBottom: "6px" }}>Access to audio chapter summaries</li>
                        <li style={{ marginBottom: "6px" }}>50 AI-generated flashcard decks per month</li>
                        <li style={{ marginBottom: "6px" }}>50 AI-generated mock exams per month</li>
                    </ul>
                </Box>
                <Box
                    bgcolor={pricingColor}
                    borderRadius={4}
                    px={4}
                    py={3}
                    flex={1}
                    width={mobile ? "100%" : "28vw"}
                    minHeight={tablet ? 500 : (mobile ? 320 : 440)}
                >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography fontWeight="bold" fontSize={18}>
                            Premium
                        </Typography>
                        <BookmarkAddedOutlined />
                    </Stack>
                    <Typography fontWeight="bold" fontSize={18}>
                        <span style={{ fontSize: "36px" }}>${premiumPrice}</span>/mo
                    </Typography>
                    <Button
                        variant="contained"
                        disableElevation
                        fullWidth
                        onClick={premiumHandler}
                        sx={{ bgcolor: "#1875d1", color: "white", mt: 1.5, mb: 4, borderRadius: 1.5, textTransform: "none", fontSize: 16 }}
                    >
                        {localStorage.getItem("sub") === "basic" || localStorage.getItem("sub") === "pro" ? "Upgrade" : 
                        (localStorage.getItem("sub") === "premium" ? "Billing Portal" : "Start for free")}
                    </Button>
                    <Typography fontWeight="bold" lineHeight={1} mb={1}>
                        Everything in Pro +
                    </Typography>
                    <ul style={{ padding: "0px 14px 0px 14px" }}>
                        <li style={{ marginBottom: "6px" }}>Chat with summaries</li>
                        <li style={{ marginBottom: "6px" }}>First priority to textbook requests</li>
                        <li style={{ marginBottom: "6px" }}>250 AI-generated flashcard decks per month</li>
                        <li style={{ marginBottom: "6px" }}>250 AI-generated mock exams per month</li>
                    </ul>
                </Box>
            </Stack>
            <Box pt='10vh' pb='8vh'>
                <Typography variant={mobile ? "h4" : "h3"} fontWeight={700} textAlign="center" mb='4.5vh' >
                    Student Testimonials:
                </Typography>
                <Testimonials spacing="3vw"/> 
            </Box>
            <Typography fontSize={mobile ? 18 : 20} fontWeight={400} textAlign="center"><b>Questions?</b> Email us at <a style={{color: primaryColor}} href="mailto:review@ezread.io">review@ezread.io</a> or click <a style={{color: primaryColor}} href="https://forms.gle/b5Zj51kKFeQcLbHq7" target="_blank">Contact</a> down below!</Typography>
        </Stack>
    );
};

export default Pricing;
