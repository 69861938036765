import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Button, Typography, Alert, Collapse, Divider} from '@mui/material';
import LoadingSpinner from '../common/LoadingSpinner.js';
import configuration from '../../config.json';
import { set } from 'mongoose';

const ProfileScreen = () => {
    const navigate = useNavigate();
    const backendUrl = configuration.backendUrl;
    const [openAlert, setOpenAlert] = useState(false);
    const [exams, setExams] = useState("");
    const [decks, setDecks] = useState("");
    const [error, setError] = useState("");
    const [sub, setSub] = useState("");
    const [loading, setLoading] = useState(false);

    const billingHandler = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(backendUrl+'/api/auth/refresh-token', {}, {withCredentials: true});
            if (res.data && res.data !== "Unauthorized") {
                if (res.data) {
                    const config = {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${res.data}`
                        },
                    };
                    const {data} = await axios.get(backendUrl+`/api/auth/user`, config);
                    if (data.subscription) {
                        if (data.customerId) {
                            const portalSession = await axios.post(backendUrl+'/api/stripe/customer-portal', {'customerId': data.customerId,});
                            window.open(portalSession.data.url, "_self")
                        } else {
                            setOpenAlert(true);
                            setError("To access your Billing Portal, please purchase a subscription!");
                        }
                     } else {
                        setError("To access your Billing Portal, please purchase a subscription!");
                        setOpenAlert(true);
                    }
                } else {
                    navigate("");
                }
            }
        } catch (error) {
            console.log(error.response.data);
        }
    }

    //const minWidth850 = useMediaQuery('(min-width:850px)');
    
    useEffect(() => {
        const setVals = async () => {
            try {
                setLoading(true);
                const res = await axios.post(backendUrl+'/api/auth/refresh-token', {}, {withCredentials: true});
                if (res.data && res.data !== "Unauthorized") {
                    if (res.data) {
                        const config = {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${res.data}`
                            },
                        };
                        const {data} = await axios.get(backendUrl+`/api/auth/user`, config);
                        //console.log(data)
                        setDecks(data.flashcardsGenerated); 
                        setExams(data.examsGenerated);
                        setSub(data.subscription);
                        if (data.subscription) {
                            localStorage.setItem("sub", data.subscription);
                        }
                        setLoading(false);
                        setTimeout(async () => {
                            const subscriptionData = await axios.get(backendUrl+`/api/auth/subscription`, config);
                            if (subscriptionData.data) {
                                localStorage.setItem("sub", subscriptionData.data);
                            }
                        }, 5000);
                    } else {
                        navigate("");
                    }
                }
            } catch (err) {
                console.log(err)
                setOpenAlert(true);
                setError("Error fetching data. Please try again later.");
            } finally {
                setLoading(false);
            }
        };
        setVals();
    }, []);

    //useEffect for error
    useEffect(() => {
        if (error) {
            setOpenAlert(true);
            //set error to "" after 5 secondds
            setTimeout(() => {
                setOpenAlert(false);
                setError("");
            }
            , 5000);
        }
    }, [error]);

  return (
    <Box sx={{display: 'flex', justifyContent: 'center',}}>
        <Box mt="6vh" mb="10vh">
            <Card sx={{p:"20px", width: "420px", color: "black", height: loading ? '40vh' : 'auto'}}>
                { loading ? <LoadingSpinner /> :
                <>
                    <Collapse in={openAlert}>
                        <Alert severity="warning" sx={{mb:2}} variant="filled" onClose={() => {setOpenAlert(false); setError("")}} >
                            {error}
                        </Alert>
                    </Collapse>
                    <Typography color="white" fontWeight={500} variant='h6' textAlign={'center'}>Current Plan:</Typography>
                    <Typography color="white" mb={2} fontWeight={700} variant='h5' textAlign={'center'}>{sub ? sub?.toUpperCase() : 'NONE' }</Typography>
                    <Typography color="white" mb={2}>Flashcard Decks generated this month: <b>{decks}</b></Typography>
                    <Typography color="white" mb={2}>Mock Exams generated this month: <b>{exams}</b></Typography>
                    <Divider color="gray"></Divider>
                    
                    <form onSubmit={billingHandler}>
                        <Button disableElevation={true} type="submit" fullWidth color="secondary" size="large" sx={{mt:"15px", textTransform:"none"}} variant="contained">
                            Access your Billing Portal
                        </Button>
                    </form>
                </>
        }

            </Card>

        </Box>
    </Box>
  );
};

export default ProfileScreen;
