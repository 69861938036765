import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Stack, Collapse, Alert, Divider, Typography, IconButton, TextField, Dialog, 
    DialogTitle, DialogContent, DialogContentText, DialogActions, Menu, MenuItem, ListItemText, 
    ListItemSecondaryAction, Tooltip, useMediaQuery
} from '@mui/material';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import LoopRoundedIcon from '@mui/icons-material/LoopRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import configuration from '../../config.json';
import './Flashcards.css';

const Flashcards = () => {
  const backendUrl = configuration.backendUrl;
  const mobile = useMediaQuery('(max-width:600px)');
  const [error, setError] = useState('');
  const [flashcards, setFlashcards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [curFlashcardNum, setCurFlashcardNum] = useState(1);
  const [front, setFront] = useState(true);
  const [file, setFile] = useState(null);
  const [text, setText] = useState('');
  const [isFlipping, setIsFlipping] = useState(false);
  const [isTransitionEnabled, setIsTransitionEnabled] = useState(true);
  const [notes, setNotes] = useState('');
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [deckName, setDeckName] = useState('');
  const [modalError, setModalError] = useState('');
  const [modalSuccess, setModalSuccess] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [decks, setDecks] = useState([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deckToDelete, setDeckToDelete] = useState(null);
  const [deckToDeleteName, setDeckToDeleteName] = useState('');
  const [deckToLoadName, setDeckToLoadName] = useState('');
  const [deckToLoadId, setDeckToLoadId] = useState(null);

  // Fetch user's flashcard decks
  const fetchDecks = async () => {
    try {
      const token = await axios.post(
        backendUrl + '/api/auth/refresh-token',
        {},
        { withCredentials: true }
      );
      if (token.data && token.data !== 'Unauthorized') {
        const config = {
          headers: {
            Authorization: `Bearer ${token.data}`,
          },
          withCredentials: true,
        };
        const response = await axios.get(
          backendUrl + '/api/ai/get-flashcard-decks',
          config
        );
        setDecks(response.data);
      } else {
        setError('Unauthorized access. Please log in again.');
      }
    } catch (error) {
      console.error(error);
      setError('Error fetching decks. Please try again.');
    }
  };

  useEffect(() => {
    fetchDecks();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setNotes('');
  };

  const handleFlashcards = async (e) => {
    e.preventDefault();
    if (!file && !notes) {
      setError('Please select a file or provide notes to generate flashcards.');
      return;
    }

    const token = await axios.post(
      backendUrl + '/api/auth/refresh-token',
      {},
      { withCredentials: true }
    );
    if (token.data && token.data !== 'Unauthorized') {
      setLoading(true);
      let formData;

      if (notes) {
        // When notes are provided, use a JSON payload
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.data}`,
          },
        };
        try {
          const { data } = await axios.post(
            backendUrl + '/api/ai/create-flashcards',
            { notes: notes },
            config
          );
          setFlashcards(data);
          setCurFlashcardNum(1);
          setDeckToLoadName('');
        } catch (err) {
          //console.error(err);
          if (err.response && err.response.data) {
            setError(err.response.data.error);
          } else if (err.message) {
            setError(err.message);
          } else
            setError('Error generating flashcards.');
        } finally {
          window.scrollTo(0, 0);
          setLoading(false);
        }
      } else {
        formData = new FormData();
        formData.append('file', file);

        try {
          const config = {
            headers: {
              Authorization: `Bearer ${token.data}`,
            },
          };

          const { data } = await axios.post(
            backendUrl + '/api/ai/create-flashcards',
            formData,
            config
          );
          setFlashcards(data);
          setCurFlashcardNum(1);
          setDeckToLoadName('');
        } catch (err) {
          console.error(err);
          if (err.response && err.response.data) {
            setError(err.response.data.error);
          } else if (err.message) {
            setError(err.message);
          } else
            setError('Error generating flashcards.');
        } finally {
          window.scrollTo(0, 0);
          setLoading(false);
        }
      }
    } else {
      setError('Unauthorized access. Please log in again.');
    }
  };

  const handleFlashcardNext = () => {
    if (curFlashcardNum < flashcards.length) {
      setIsTransitionEnabled(false); // Disable animation
      setCurFlashcardNum(curFlashcardNum + 1);
      setFront(true);
    }
  };

  const handeFlashcardPrior = () => {
    if (curFlashcardNum > 1) {
      setIsTransitionEnabled(false); // Disable animation
      setCurFlashcardNum(curFlashcardNum - 1);
      setFront(true);
    }
  };

  const handlePlaySpeech = () => {
    if (text.trim() !== '') {
      const speech = new SpeechSynthesisUtterance(text);
      window.speechSynthesis.speak(speech);
    }
  };

  const handleFlipCard = () => {
    setIsTransitionEnabled(true); // Enable animation
    setFront(!front);
    setIsFlipping(true); // Start flipping
    setTimeout(() => {
      setIsFlipping(false); // End flipping
    }, 300); // Set timeout duration to match CSS flip transition time
  };

  const handleOpenSaveModal = () => {
    setOpenSaveModal(true); // Open modal
  };

  const handleCloseSaveModal = () => {
    setOpenSaveModal(false); // Close modal
  };

  const handleSaveDeck = async () => {
    if (!deckName.trim()) {
      setModalError('Please enter a deck name.');
      return;
    }

    try {
      const token = await axios.post(
        backendUrl + '/api/auth/refresh-token',
        {},
        { withCredentials: true }
      );
      if (token.data && token.data !== 'Unauthorized') {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.data}`,
          },
        };
        const response = await axios.post(
          backendUrl + '/api/ai/save-flashcards',
          { deckName, flashcards },
          config
        );
        setModalSuccess('Deck saved successfully!');
        // Fetch decks again to update the list
        fetchDecks();
        // Wait 2 seconds and close the modal
        setTimeout(() => {
          setOpenSaveModal(false);
          setModalSuccess('');
        }, 2000);
      } else {
        setModalError('Unauthorized access. Please log in again.');
      }
    } catch (error) {
      console.error(error);
      setModalError('Error saving deck. Please try again.');
    }
  };

  const handleDeleteClick = (deckId, deckName) => {
    setDeckToDelete(deckId);
    setDeckToDeleteName(deckName);
    setDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!deckToDelete) return;

    try {
      const token = await axios.post(
        backendUrl + '/api/auth/refresh-token',
        {},
        { withCredentials: true }
      );
      if (token.data && token.data !== 'Unauthorized') {
        const config = {
          headers: {
            Authorization: `Bearer ${token.data}`,
          },
        };
        await axios.delete(
          backendUrl + `/api/ai/delete-flashcard-deck/${deckToDelete}`,
          config
        );
        // Update the deck list after deletion
        fetchDecks();
        setDeleteConfirmOpen(false);
        setDeckToDelete(null);
        setDeckToDeleteName('');
        //if deckToDelete is the current deck, reload page
        if (deckToDelete === deckToLoadId) {
            setFlashcards([]);
            setNotes('');
            setFile(null);
            setCurFlashcardNum(1);
            setDeckToLoadName('');
            setDeckToLoadId(null);
            window.location.reload();
        }
      } else {
        setError('Unauthorized access. Please log in again.');
      }
    } catch (error) {
      console.error(error);
      setError('Error deleting deck. Please try again.');
    }
  };

  const handleCancelDelete = () => {
    setDeleteConfirmOpen(false);
    setDeckToDelete(null);
    setDeckToDeleteName('');
  };

  // Handle menu open/close
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLoadDeck = async (deckId, deckName) => {
    handleMenuClose(); // Close the menu
    try {
      const token = await axios.post(
        backendUrl + '/api/auth/refresh-token',
        {},
        { withCredentials: true }
      );
      if (token.data && token.data !== 'Unauthorized') {
        const config = {
          headers: {
            Authorization: `Bearer ${token.data}`,
          },
        };
        const response = await axios.get(
          backendUrl + `/api/ai/get-flashcard-deck/${deckId}`,
          config
        );
        setFlashcards(response.data.cards);
        setCurFlashcardNum(1);
        setDeckToLoadName(deckName);
        setDeckToLoadId(deckId);
        setFront(true);
        setFile(null);
        setNotes('');
      } else {
        setError('Unauthorized access. Please log in again.');
      }
    } catch (error) {
      console.error(error);
      setError('Error loading deck. Please try again.');
    }
  };

  // UseEffect for error messages
  useEffect(() => {
    if (error) {
      setLoading(false);
      setTimeout(() => {
        setError('');
      }, 7000);
    } else if (modalError) {
      setTimeout(() => {
        setModalError('');
      }, 5000);
    }
  }, [error, modalError]);

  // UseEffect for front text content
  useEffect(() => {
    if (front && flashcards.length > 0) {
      setText(flashcards[curFlashcardNum - 1].front);
    } else if (!front && flashcards.length > 0) {
      setText(flashcards[curFlashcardNum - 1].back);
    }
  }, [front, flashcards, curFlashcardNum]);

  return (
    <Box px="4vw" py="2vh">
      <Collapse in={error}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      </Collapse>
      <Stack direction="row" alignItems="center">
        <Typography fontWeight="bold" variant={mobile ? "h5" : "h4"} mt={2} mb={mobile ? 1 : 2}>
          Flashcards
        </Typography>
        <div>
          {decks.length > 0 && 
            <Tooltip title="Saved Decks" placement='right'>
                <IconButton onClick={handleMenuOpen}>
                    <MoreVertIcon />
                </IconButton>
            </Tooltip>}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {decks.map((deck) => (
              <MenuItem
                key={deck._id}
                onClick={() => handleLoadDeck(deck._id, deck.name)}
              >
                <ListItemText primary={deck.name} />
                <Box width={deck.name ? deck.name.length*2 + 20 : 40}/>
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering onClick on MenuItem
                      handleDeleteClick(deck._id, deck.name);
                    }}
                  >
                    <DeleteRoundedIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Stack>
      <Divider variant="fullWidth" color="#374e69" />
      {flashcards.length < 1 && (
        <>
          <TextField
            label="Paste notes here..."
            size="small"
            multiline
            variant="outlined"
            value={notes}
            fullWidth={notes.length > 0}
            sx={{ mt: 2, mb: 1 }}
            onChange={(e) => setNotes(e.target.value)}
          />
          <Typography mb={1} mt={0.5}>
            OR
          </Typography>
          <input
            type="file"
            accept=".docx, .pptx, .xlsx, .pdf"
            onChange={handleFileChange}
            style={{ marginBottom: '1rem' }}
          />
          <br />
          <Button
            onClick={handleFlashcards}
            variant="outlined"
            disabled={loading || (!file && !notes)}
            sx={{ mr: 10 }}
          >
            {loading ? 'Generating...' : 'Generate Flashcards'}
          </Button>
        </>
      )}
      {flashcards.length > 0 && (
        <Stack alignItems="center" mt={mobile ? 2 : 4}>
          {deckToLoadName && (
            <Typography variant={mobile ? "h6" : "h5"} mb={2} fontWeight="bold">
              {deckToLoadName}
            </Typography>
          )}
          <Box
            className={`flashcard ${front ? '' : 'flipped'} ${
              isTransitionEnabled ? '' : 'no-transition'
            }`}
            bgcolor="#2f3856"
            onClick={handleFlipCard}
            sx={{
              width: mobile ? '80vw' : '40vw',
              minHeight: mobile ? 200 : 400,
              borderRadius: 4,
              cursor: 'pointer',
              p: 4,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handlePlaySpeech();
              }}
              className={`volume-button ${isFlipping ? 'hidden' : ''}`}
              sx={{
                color: 'white',
                position: 'absolute',
                top: front ? 8 : 'inherit',
                right: 8,
                bottom: !front ? 8 : 'inherit',
              }}
            >
              <VolumeUpRoundedIcon />
            </IconButton>
            <div className="flashcard-front">
              <Typography fontSize={ mobile ? 24 : 30 }>
                {flashcards[curFlashcardNum - 1]?.front}
              </Typography>
            </div>
            <div className="flashcard-back">
              <Typography fontSize={mobile ? 22 : 30}>
                {flashcards[curFlashcardNum - 1]?.back}
              </Typography>
            </div>
          </Box>
          <Stack direction="row" spacing={2} alignItems="center" mt={3}>
            <Button
              variant="outlined"
              startIcon={<NavigateBeforeRoundedIcon />}
              onClick={handeFlashcardPrior}
              disabled={curFlashcardNum === 1}
              size={mobile ? "small" : "medium"}
              sx={{width: mobile ? 10 : 'inherit'}}
            >
              {mobile ? "" : "Next"}
            </Button>
            <Typography fontWeight="bold">
              {' '}
              {curFlashcardNum} / {flashcards.length}
            </Typography>
            <Button
              variant="outlined"
              endIcon={<NavigateNextRoundedIcon />}
              disabled={curFlashcardNum === flashcards.length}
              onClick={handleFlashcardNext}
              size={mobile ? "small" : "medium"}
              sx={{width: mobile ? 30 : 'inherit'}}
            >
                {mobile ? "" : "Back"}
            </Button>
          </Stack>
          <Stack direction={mobile ? "column" : "row"} spacing={mobile ? 1.25 : 2} alignItems="center" mt={mobile ? 3.5 : 5}>
            <Button
              variant="contained"
              disableElevation={true}
              endIcon={<SaveRoundedIcon />}
              onClick={handleOpenSaveModal}
              disabled={deckToLoadName !== ''}
              size={mobile ? "small" : "medium"}
            >
              Save Deck
            </Button>
            <Button
              color="info"
              variant="contained"
              disableElevation={true}
              endIcon={<LoopRoundedIcon />}
              onClick={() => {
                setFlashcards([]);
                setNotes('');
                setFile(null);
                setCurFlashcardNum(1);
                setDeckToLoadName('');
                setDeckToLoadId(null);
              }}
              size={mobile ? "small" : "medium"}
            >
              New Deck
            </Button>
          </Stack>
        </Stack>
      )}
      {/* Dialog (Modal) for saving the deck */}
      <Dialog open={openSaveModal} onClose={handleCloseSaveModal}>
        <DialogTitle>Save Deck</DialogTitle>
        <DialogContent>
          <Collapse in={modalError.length > 0}>
            <Alert severity="error" sx={{ mb: 2 }}>
              {modalError}
            </Alert>
          </Collapse>
          <Collapse in={modalSuccess.length > 0}>
            <Alert severity="success" sx={{ mb: 2 }}>
              {modalSuccess}
            </Alert>
          </Collapse>
          <TextField
            autoFocus
            margin="dense"
            label="Deck Name"
            type="text"
            fullWidth
            variant="standard"
            value={deckName}
            onChange={(e) => setDeckName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSaveModal}>Cancel</Button>
          <Button
            onClick={handleSaveDeck}
            variant="contained"
            disableElevation={true}
            disabled={!deckName}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* Confirmation Dialog for Deleting a Deck */}
      <Dialog open={deleteConfirmOpen} onClose={handleCancelDelete}>
        <DialogTitle>Delete Deck</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the deck "{deckToDeleteName}"? This
            action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Flashcards;
