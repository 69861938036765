import * as React from 'react';
import { Box, Button, Collapse, Alert, Divider, TextField, useMediaQuery, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import {Google as GoogleIcon} from '@mui/icons-material';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate, Link } from 'react-router-dom';
import configuration from '../../config.json';

const RegisterScreen = () => {
    const theme = useTheme();
    const backendUrl = configuration.backendUrl;
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const mobile = useMediaQuery("(max-width: 600px)");
    const navigate = useNavigate();

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");

    const config = {
        headers: { "Content-Type": "application/json" },
    }

    const registerHandler = async (e) => { 
        e.preventDefault();

        try {
            setSuccess("");
            await axios.post(backendUrl+"/api/auth/register", {email, password}, config);
            //if successful, create alert statring that the user has been created and email has been sent for verification
            setSuccess("Account creation successful! Please check your email for a verification link.");
        } catch (err) {
            console.log(err);
            if (err.response.data.error) {
                setError(err.response.data.error);
            } else if (err.message) {
                setError(err.message);
            }
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    }

    const [ user, setUser ] = React.useState("");
    const googleRegister = useGoogleLogin({
        onSuccess: (codeResponse) => {setUser(codeResponse.access_token); console.log(codeResponse);},
        onError: (error) => console.log('Login Failed:', error)
    });

    React.useEffect(
        () => {
            if (user) {
                //console.log(user)
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user}`, {
                        headers: {
                            Authorization: `Bearer ${user}`,
                            Accept: 'application/json'
                        }
                    })
                    .then(async (res) => {
                        const name = res.data.name;
                        const google_email = res.data.email;
                        const verified_email = res.data.verified_email;
                        const { data } = await axios.post(backendUrl+"/api/auth/google-register", {name, google_email, verified_email}, config);
                        if (data.accessToken) {
                            localStorage.setItem("authToken", 'true');
                            navigate("/search/query=2000,2011,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022&subject=&publisher=&edition=&year=&page=1&sortBy=Default");
                            window.location.reload();
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        if (err.response.data.error) {
                            setError(err.response.data.error);
                        } else {
                            setError(err.message);
                        }
                    });
            }
        },
        [ user ]
    );

    //useEffect for error
    React.useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    }, [error]);
    return (
        <Box width={isNotMobile ? "32%" : "80%" } 
            p="3rem" 
            m="2rem auto" 
            my={isNotMobile ? 10 : 8}
            borderRadius={5} 
            bgcolor={theme.palette.background.paper} 
            sx={{boxShadow:5}}
        >
            <Collapse in={error.length > 0}>
                <Alert severity="error" sx={{mb:2}}>{error}</Alert>
            </Collapse>
            <Collapse in={success.length > 0}>
                <Alert severity="success" sx={{mb:2}}>{success}</Alert>
            </Collapse>
            <form onSubmit={registerHandler}>
                <Typography variant={mobile ? "h4" : "h3"} fontWeight="bold" mb={3} textAlign="center">Sign Up</Typography>

                <Button variant="outlined" size="large" fullWidth sx={{py: 1.3}}
                    startIcon={<GoogleIcon/>}
                    onClick={() => googleRegister()}
                >
                    Sign up with Google
                </Button>

                <Divider sx={{mt: mobile ? 2 : 3, mb: mobile ? 1 : 2}}> <Typography fontWeight="bold" color="grey">OR</Typography> </Divider>

                <Typography variant="h6" fontWeight={500} mb={1}>Email</Typography>
                <TextField sx={{".MuiOutlinedInput-root": { boxShadow: 1 }, mb: mobile ? 1.5 : 2}} size={mobile ? "small" : "medium"}
                required fullWidth value={email} onChange={(e) => setEmail(e.target.value)}/>

                <Typography variant="h6" fontWeight={500} mb={1}>Password</Typography>
                <TextField type="password" sx={{".MuiOutlinedInput-root": { boxShadow: 1 }, mb: mobile ? 3 : 4}} size={mobile ? "small" : "medium"}
                required fullWidth value={password} onChange={(e) => setPassword(e.target.value)}/>

                <Button type="submit" variant="contained" fullWidth size={mobile ? "medium" : "large"} sx={{py: mobile ? 1 : 1.3}}> Sign Up </Button>
                
                <Divider sx={{my: mobile ? 2.5 : 4}}></Divider>

            </form>


            <Typography textAlign="center">Already have an account? <Link style={{color: theme.palette.primary.main}} to="/login"><b>Sign in</b></Link></Typography>
        </Box>
    );
  }
  
  export default RegisterScreen;
