import { Card, Stack, Typography } from '@mui/material';

export default function TestimonalCard({src, alt, quote, year, fName, school}) {
  return (
    <Card sx={{height:390, width:290, boxShadow:'0 3px 12px rgb(0 0 0 / 0.3)', bgcolor:"#1a2e44"}}>
        <img src={src} alt={alt} style={{width:"290px", height:"220px", borderRadius: 0}} />
        <Stack mt={"10px"} ml={"12px"} width={"270px"}>
                <Typography fontSize="16px" fontWeight={700}>
                "{quote}"
                </Typography>
                <Typography fontSize="15px" mt="15px" fontWeight={500}>
                - {fName}, {school} '{year} 
                </Typography>
        </Stack>
    </Card>
  );
}


