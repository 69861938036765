import {createTheme} from "@mui/material/styles";
import { colors } from "@mui/material";

// Create a theme instance.
export const themeSettings = (mode) => createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1875d1",
      //light: "#123456",
    },
    secondary: {
      main: colors.yellow.A700,
    },
    background: {
      default: "#142336",
      paper: "#162c47",
    },
    error: {
      main: colors.red.A400,
    },
  },
});

export default themeSettings;
